<template>
  <div class="container pb-3" id="pulse-check-overview">
    <div class="mt-4" v-if="loading">
      <loader></loader>
    </div>
    <div class="row mt-4" v-else>
      <div class="col-sm-12 col-lg-8 offset-lg-2 mt-2">
        <h4>{{ data.title }}</h4>
      </div>
      <div class="col-sm-12 col-lg-8 offset-lg-2 mt-2">
        <ul class="list-group mt-3" v-if="items">
          <router-link
            tag="li"
            :to="{ name: 'pulseCheckContent', params: { overview: $route.params.id, id: item.id } }"
            class="list-group-item mb-3 pointer"
            v-for="item in items"
            :key="item.id"
          >
            <div class="row">
              <div class="col-sm-7 col-md-8 col-lg-8 text-left">
                <h6 class="mt-2" :style="{ color: style.pulseCheckTitleColor }">{{ item.title }}</h6>
              </div>
              <div class="col-sm-5 col-md-4 col-lg-4 rating-column">
                <el-rate
                  v-model="item.rating"
                  :colors="[style.pulseCheckStarColor, style.pulseCheckStarColor, style.pulseCheckStarColor]"
                  disabled
                ></el-rate>
              </div>
            </div>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Rate } from 'element-ui';
import sortBy from 'lodash/sortBy';

export default {
  components: {
    elRate: Rate
  },
  created() {
    this.$http.get(`dashboard/pulsechecks/${this.$route.params.id}`).then(
      (response) => {
        this.data = response.data;
        this.loading = false;
      },
      (err) => {
        this.$notify({
          type: 'error',
          message: this.$t('notifications.error'),
          position: 'bottom-right'
        });
        // go back by one record, the same as history.back()
        this.loading = false;
        this.$router.go(-1);
      }
    );
  },
  computed: {
    ...mapGetters('settings', ['style']),
    ...mapGetters('dashboard', ['dashboardData']),
    items() {
      return this.data.groupData
        .filter((pulseCheck) => pulseCheck.isVisibleForUser)
        .map((pulseCheck) => {
          pulseCheck.rating = Math.round(pulseCheck.rating * 2) / 2;
          return pulseCheck;
        });
    }
  },
  data() {
    return {
      data: { groupData: [] },
      loading: true
    };
  }
};
</script>

<style lang="scss">
#pulse-check-overview {
  .el-rate__icon {
    font-size: 1.7rem;
  }

  @media (max-width: 767px) {
    .rating-column .el-rate__icon {
      font-size: 28px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .rating-column:not(.full-width) .el-rate .el-rate__item .el-rate-icon {
      font-size: 1.6rem !important;
    }
  }

  @media (max-width: 767px) {
    .el-rate-icon {
      font-size: 1.8rem;
    }
    .el-rate {
      margin-top: 0.5rem;
      height: 2rem !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.list-group-item a {
  font-size: 110%;
}
</style>
